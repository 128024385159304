.tabs-trapezoid {
  .nav-pills {
    white-space: nowrap;
    overflow-x: auto;
  }
  .nav-pills>li>a {
    color: #fff;
    background: transparent;
    border-bottom: 30px solid #a5a5a5;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 0;
    line-height: 30px;
    width: auto;
    text-align: center;
    margin: 0;
    padding: 0 35px;
    z-index: 5;
    i {
      position: absolute;
      left: 5px;
      top: 9px;
    }
  }
  .nav-pills>li {
    display: inline-block;
    float: none;
    margin: 0;
  }
  .nav-pills>li+li {
    margin-left: -5px;
  }
  .nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {
    background: transparent;
    border-bottom-color: #444;
    z-index: 10;
    &.electricity {
      border-bottom-color: @colorDanger;
    }
    &.gas {
      border-bottom-color: #682d93;
    }
    &.heat {
      border-bottom-color: @colorWarning;
    }
    &.water {
      border-bottom-color: @colorPrimary;
    }
    &.summary {
      border-bottom-color: @colorInfo;
    }
    &.cgs {
      border-bottom-color: #2a8207;
    }
  }
  .nav>li>a:hover, .nav>li>a:focus {
    background: transparent;
    border-bottom-color: #cbcbcb;
    //z-index: 15;
  }
  .tabs-content {
    border-top: 5px solid #444;
    &.electricity {
      border-top-color: @colorDanger;
    }
    &.gas {
      border-top-color: #682d93;
    }
    &.heat {
      border-top-color: @colorWarning;
    }
    &.water {
      border-top-color: @colorPrimary;
    }
    &.summary {
      border-top-color: @colorInfo;
    }
    &.cgs {
      border-top-color: #2a8207;
    }
  }
}

.fa.sum {
  font-size: 135%;
  font-weight: bold;
  &:before {
    content: "\03A3";
  }
}
