.layouts-form {
  position: relative;
  top: -15px;
  margin: 0 -15px;

  p {
    padding: 6px 20px;
    margin-bottom: 15px;
  }

  .table-responsive {
    border: none;
  }

  .table td, .table th {
    border: none;
    padding: 0;
  }

  .form-modal-well {
    padding: 0 15px;
  }

  .form-row {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    max-width: 180px;
  }

  .well-right,
  .well-left {
    text-align: center;
    > * {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: 768px) {
  .layouts-form {
    .well-right {
      float: right;
    }
    .well-left {
      float: left;
    }
    .well-right,
    .well-left {
      > * {
        margin-bottom: 0;
      }
    }
  }
}
