invoicing-place {

}
.invoicing-place-logo {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    overflow: hidden;
    height: 226px;
    width: 200px;
    background: #f2f2f2;
}
.invoicing-place-logo>img {
    height: auto;
    width: 100%;
    position: relative;
    top: 30px;
    left: 5px;
}

@media (min-width: @screen-sm-min) {
    .invoicing-place-logo {
        display: block;
    }
}
