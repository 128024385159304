.footerBg() when (@footerLinearBg) {
    background: linear-gradient(@footerBg, #fff);
}

footer {
    margin: 0;
    padding: (@indent / 2) @indent;
    line-height: 17px;
    background: @footerBg;
    .footerBg();
    border-top: 1px solid (@footerBg - #111);
}

@media (min-width: @screen-md-min) {
    footer {
        margin-left: @sidebarMini;
    }
}
