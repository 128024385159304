@em-0-bg: #db1922;
@em-0-bg-light: #fff;
@em-1-bg: #55abf6;
@em-1-bg-light: #f3f8fe;
@em-2-bg: #2a8207;
@em-2-bg-light: #f4f9f2;
@em-3-bg: #f7c280;
@em-3-bg-light: #fffcf9;
@em-4-bg: #2a217c;
@em-4-bg-light: #f5f4f9;
@em-5-bg: #f999e0;
@em-5-bg-light: #fffafe;

.bg-stripes(@color) {
    background: repeating-linear-gradient(135deg, @color, @color 2px, darken(@color, 3%) 2px, darken(@color, 3%) 4px);
}

.energy-tabs {
    .nav {
        margin-right: 60px;
    }
}

.tabs-table {
    position: relative;
    .cell-padded {
        padding: 0 5px;
    }
    .tabs-table-actions {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        padding: 12px 0;
        padding-right: 15px;
    }
    .tabs-table-btn {
        color: #444;
        display: inline-block;
        width: 40px;
        text-align: center;
        font-size: 17px;
    }
    .btn-group.open .tabs-table-btn {
        background: #e0e0e0;
        color: #444;
        i {
            color: #444;
        }
    }
    .dropdown-menu {
        border: 0;
        min-width: 250px;
        .divider {
            margin: 5px 0;
        }
        > li > a {
            font-size: 13px;
            padding: 2px 10px;
        }
    }
    .dropdown-header {
        font-size: 13px;
        text-align: center;
    }
}

.energy-table-actions {
    position: absolute;
    top: -35px;
    right: 0px;
    z-index: 10;

    .btn-group > a {
        display: inline-block;
        height: 0;
        width: 60px;
        line-height: 30px;
        font-size: 17px;
        text-align: center;
        color: #fff;
        border-right: 10px solid transparent;
        border-bottom: 30px solid #444;
        border-left: 10px solid transparent;

        &:focus, &:hover {
            color: #fff;
            border-bottom-color: @brand-primary;
        }
    }

    .line {
        height: 5px;
        background: #444;
    }
}

app-energy-table {
    .clearfix;
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 2px 5px 0px rgba(0,0,0,.5);
    .table-columns {
        display: flex;
    }
    .table-column {
        flex: 1;
        position: relative;
        min-height: 1px;
        background: #fff;
    }
    .table-column:last-child {
        flex: 0 0 200px;
        max-width: 200px;
    }
    .table-cell {
        display: none;
        position: relative;
        padding: 0 5px;
        height: 40px;
        line-height: 40px;
        background: #fff;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        white-space: nowrap;
        &.in {
            display: block;
        }
        &.head {
            display: block;
            border-right: 1px solid #444;
            border-bottom: 1px solid #444;
            background: #444;
            color: #fff;
        }
        &.level-0 {
            color: @em-0-bg;
            font-weight: 700;
            border-top: 1px solid #ddd;
        }
        &.level-1 {
            background: @em-1-bg-light;
        }
        &.level-2 {
            background: @em-2-bg-light;
        }
        &.level-3 {
            background: @em-3-bg-light;
        }
        &.level-4 {
            background: @em-4-bg-light;
        }
        &.level-5 {
            background: @em-5-bg-light;
        }
    }
    .col-space {
        padding: 0;
        height: 15px;
        box-shadow: inset 0 6px 6px -6px rgba(0,0,0,.5);
    }
    .col-shallow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        z-index: 5;
        background: transparent;
        box-shadow: inset 0 -2px 6px -4px rgba(0, 0, 0, 0.5);
    }
    .last {
        padding: 0;
    }
    .halfcell {
        text-align: right;
        width: 100%;
        td {
            width: 50%;
            padding: 0 5px;
        }
    }

    .segmented {
        display: inline-block;
        font-size:16px;
        text-align:center;
        margin: 0 auto;
        padding: 4px;
    }

    .segmented label {
        border: 1px #ccc;
        border-style: solid none solid solid;
        cursor: pointer;
        float: left;
        box-sizing:border-box;
        margin:0;
        text-align: center;
        min-width: 100px;
        font-weight: normal;
        background: white;
        &.level-0 {
            color: @em-0-bg;
        }
        &.level-1 {
            color: @em-1-bg;
        }
        &.level-2 {
            color: @em-2-bg;
        }
        &.level-3 {
            color: @em-3-bg;
        }
        &.level-4 {
            color: @em-4-bg;
        }
        &.level-5 {
            color: @em-5-bg;
        }
    }

    .segmented label.checked {
        background: #2098ca;
        color:#fff;
        font-weight: normal;
        &.level-0 {
            background: @em-0-bg;
            color: @em-0-bg-light;
        }
        &.level-1 {
            background: @em-1-bg;
            color: @em-1-bg-light;
        }
        &.level-2 {
            background: @em-2-bg;
            color: @em-2-bg-light;
        }
        &.level-3 {
            background: @em-3-bg;
            color: @em-3-bg-light;
        }
        &.level-4 {
            background: @em-4-bg;
            color: @em-4-bg-light;
        }
        &.level-5 {
            background: @em-5-bg;
            color: @em-5-bg-light;
        }
    }
    .segmented label.disabled {
        background: #e1e1e1;
        color: #919191;
        font-weight: normal;
    }
    .segmented label:last-child {
        border-right-style: solid;
    }
    .segmented input[type="radio"] {
        display: none;
    }
}
@media (max-width: @screen-xs-max) {
    app-energy-table .table-column {
        overflow: auto;
    }
}
@media (min-width: @screen-md-min) {
    app-energy-table {
        padding: 0 15px;
    }
    app-energy-table .table-cell.head {
        height: 60px;
        padding-top: 10px !important;
    }
}

app-energy-table-actions {
    display: none;
    margin: 0 -6px 0 -5px;
    position: relative;
    .links {
        background: #fff;
        z-index: 10000;
        position: fixed;
        display: block;
        top: ~"calc(50% - 45px)";
        left: ~"calc(50% - 110px)";
        width: 220px;
        padding: 25px;
        text-align: left;
        white-space: normal;
    }
    .backdrop {
        position: fixed;
        z-index: 1010;
    }
    #toggle {
        position: absolute;
        top: 0;
        right: 1px;
    }
}
@media (min-width: @screen-md-min) {
    app-energy-table-actions #links {
        &.maxx {
            max-width: 160px;
            overflow: hidden;
        }
        .tabs-table-btn {
            margin-right: -3px;
        }
    }
    .head .tabs-table-btn > i {
        color: #e0e0e0;
    }
}
app-energy-table-actions[loaded] {
    display: block;
}

app-energy-table-toggle {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    > span {
        position: absolute;
        right: 3px;
        font-size: 15px;
    }
}

.popcell {
    display: flex;
    height: 40px;
    line-height: initial;
    width: 100%;
    cursor: pointer;
    i {
        font-size: 22px;
    }
}
.popcell-item {
    flex-grow: 1;
}

.bg-progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    .bg-stripes(#fbe2e5);
}
.level-1 {
    .bg-progress {
        .bg-stripes(#dfeffe);
    }
}
.level-2 {
    .bg-progress {
        .bg-stripes(#dcebd6);
    }
}
.level-3 {
    .bg-progress {
        .bg-stripes(#fff8ed);
    }
}
.level-4 {
    .bg-progress {
        .bg-stripes(#d9d8e6);
    }
}
.level-5 {
    .bg-progress {
        .bg-stripes(#feeef9);
    }
}

.level-padd {
    height: ~"calc(100% + 1px)";
    width: 2px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #444;
    &.level-0 {
        background: @em-0-bg;
    }
    &.level-1 {
        left: 2px;
        background: @em-1-bg;
    }
    &.level-2 {
        left: 4px;
        background: @em-2-bg;
    }
    &.level-3 {
        left: 6px;
        background: @em-3-bg;
    }
    &.level-4 {
        left: 8px;
        background: @em-4-bg;
    }
    &.level-5 {
        left: 10px;
        background: @em-5-bg;
    }
}
@media (min-width: @screen-md-min) {
    .level-padd {
        width: 4px;
        &.level-1 {
            left: 4px;
        }
        &.level-2 {
            left: 8px;
        }
        &.level-3 {
            left: 12px;
        }
        &.level-4 {
            left: 16px;
        }
        &.level-5 {
            left: 20px;
        }
    }
}

.level-border {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 5;
    background: transparent;
    border-top: 1px solid @em-0-bg;
    box-shadow: inset 0 4px 6px -4px rgba(0, 0, 0, 0.5);
    &.level-1 {
        border-color: @em-1-bg;
    }
    &.level-2 {
        border-color: @em-2-bg;
    }
    &.level-3 {
        border-color: @em-3-bg;
    }
    &.level-4 {
        border-color: @em-4-bg;
    }
    &.level-5 {
        border-color: @em-5-bg;
    }
}

.namecell {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    padding-right: 8px;
}
@media (min-width: @screen-md-min) {
    .level-1 .namecell {
        padding-left: 8px;
    }
    .level-2 .namecell {
        padding-left: 12px;
    }
    .level-3 .namecell {
        padding-left: 16px;
    }
    .level-4 .namecell {
        padding-left: 20px;
    }
    .level-5 .namecell {
        padding-left: 24px;
    }
}

.level-1 .namecell .es {
    color: @em-1-bg;
}
.level-2 .namecell .es {
    color: @em-2-bg;
}
.level-3 .namecell .es {
    color: @em-3-bg;
}
.level-4 .namecell .es {
    color: @em-4-bg;
}
.level-5 .namecell .es {
    color: @em-5-bg;
}

.en-table-padd {
    position: absolute;
    top: 0;
    width: 30px;
    height: 80px;
    //z-index: 1;
    background: #444;
}

.namecell-edit {
    position: absolute;
    top: -12px;
    right: 2px;
    .hidden-xs;
    .hidden-sm;
}

.table-column-form {
    .form-control {
        height: 40px;
    }

    .radio, .checkbox {
        margin: 0;
        padding: 5px;
    }
}

app-energy-table-popform {
    display: none;
}
app-energy-table-popform[loaded] {
    display: block;
}

.medium-naturalGas {
  color: #682d93;
  .bg-progress {
    .bg-stripes(#e0d4e9);
  }
}
.medium-heat {
  color: #f66f0d;
  .bg-progress {
    .bg-stripes(#fde2cf);
  }
}
.medium-water {
  color: #56a8ec;
  .bg-progress {
    .bg-stripes(#ddeefb);
  }
}
