body {
    font-family: 'Open Sans', sans-serif;
    background: #fff;
    color: @colorDefault;
    font-weight: 100;
    font-size: 13px;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
}

.page-header {
    margin: 0 0 @indent;
}

.tooltip {
    font-family: 'Open Sans', sans-serif;
}
.tooltip-inner {
    padding: 4px 8px;
    .border-radius(0);
}

.popover {
    font-family: 'Open Sans', sans-serif;
    .border-radius(0);
}
.popover-content {
    padding: 4px 8px;
}

hr {
    margin: @indent 0;
}

/* Tables */
tr.primary>td.anchor {
    background: @colorPrimary !important;
}
tr.success>td.anchor {
    background: @colorSuccess !important;
}
tr.info>td.anchor {
    background: @colorInfo !important;
}
tr.warning>td.anchor {
    background: @colorWarning !important;
}
tr.danger>td.anchor {
    background: @colorDanger !important;
}
.datatable>thead>tr>th, .datatable>tfoot>tr>th, .datatable>tbody>tr>td {
    padding: 7px 5px;
    border: 2px solid #fff;
}
.datatable>thead>tr>th, .datatable>tfoot>tr>th {
    color: @colorDefault;
    background: #ddd;
    text-align: center;
    font-weight: 700;
}
.datatable>tbody>tr>td {
    color: @colorDefault;
    background: #fff;
    font-weight: 300;
}
.datatable>tbody>tr>td.anchor {
    width: 15px;
}
.datatable>tbody>tr>td.actions {
    padding: 2px 5px;
    text-align: center;
}
.datatable>tbody>tr:hover>td {
    color: @colorDefault;
    background: #f5f5f5;
}

/* Alerts */
.alert {
    display: block;
    margin: 0;
    margin-bottom: @indent;
    padding: 0;
    width: 100%;
    border: none;
    cursor: pointer;
    color: #fff;
    .clearfix();
    .border-radius(0px);
    .box-shadow(0px 12px 25px 0px rgba(0,0,0,0.33));
}
.alert-icon {
    display: table-cell;
    width: 50px;
    vertical-align: middle;
    text-align: center;
    font-size: 30px;
    background: transparent;
}
.alert-icon > i {
    opacity: 0.5;
}
.alert-icon > i:before {
    content: "\f129";
}
.alert-body {
    display: table-cell;
    padding: @indent;
    padding-right: 24px;
    text-align: justify;
    font-size: 15px;
    background: transparent;
}
.alert-close {
    position: absolute;
    top: 0;
    right: 8px;
    line-height: 50px;
    opacity: 0.5;
}
.alert:hover .alert-close, .alert:hover .alert-icon > i {
    opacity: 1;
}
.alert-info {
    background: @colorInfo;
    color: #fff;
}
.alert-info .alert-icon {
    background: #698287;
}
.alert-info .alert-icon > i:before {
    content: "\f129";
}
.alert-success {
    background: @colorSuccess;
    color: #fff;
}
.alert-success .alert-icon {
    background: #75816D;
}
.alert-success .alert-icon > i:before {
    content: "\f00c";
}
.alert-warning {
    background: @colorWarning;
    color: #fff;
}
.alert-warning .alert-icon {
    background: #838168;
}
.alert-warning .alert-icon > i:before {
    content: "\f12a";
}
.alert-danger {
    background: @colorDanger;
    color: #fff;
}
.alert-danger .alert-icon {
    background: #846C6C;
}
.alert-danger .alert-icon > i:before {
    content: "\f11a";
}
.alert.alert-error {
    background: @colorDanger;
    color: #fff;
}
.alert-error .alert-icon {
    background: #846C6C;
}
.alert-error .alert-icon > i:before {
    content: "\f11a";
}

/* Labels */
.label {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background: none;
    .border-radius(0px);
}
.label-default {
    color: #fff;
    background: @colorDefault;
}
.label-primary {
    color: #fff;
    background: @colorPrimary;
}
.label-info {
    color: #fff;
    background: @colorInfo;
}
.label-success {
    color: #fff;
    background: @colorSuccess;
}
.label-warning {
    color: #fff;
    background: @colorWarning;
}
.label-danger {
    color: #fff;
    background: @colorDanger;
}

/* Badges */
.badge {
    font-size: inherit;
    color: #fff;
    background: @colorDefault;
}

/* Lists */
dl {
    margin: 0 0 @indent;
}

/* Modals */
.modal-content {
    .border-radius(0);
}
.modal-header {
    padding: (@indent/2) @indent;
}
.modal-header .close {
    margin-top: 0;
}
.modal-body {
    padding: @indent;
}
.modal-footer {
    border: none;
    padding: 0 @indent @indent;
}
@media (min-width: 768px) {
    .modal-dialog {
        margin-top: @indent;
    }
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        text-align: left;
    }
}
