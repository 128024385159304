@efficiencyOfWork__green: #aed685;
@efficiencyOfWork__red: #ff1720;

.efficiencyOfWork {
  &__info {
    .clearfix;
    position: relative;
    margin-bottom: 25px;

    &__buttons {
      text-align: center;
      margin-bottom: 15px;

      @media (min-width: 1280px) {
        position: absolute;
        text-align: right;
        right: 0;
      }
    }

    &__table {
      @media (min-width: 1280px) {
        float: left;
        width: 58%;
        margin-left: 22%;
      }
    }
  }

  &__table {
    .table;
    margin: 0;

    > tbody > tr > td {
      border: none;
      padding: 5px 15px;

      &:first-child {
        width: 80%;
        font-size: 14px;
      }

      &:nth-child(2) {
        width: 10%;
        color: @colorPrimary;
        font-weight: bold;
        text-align: right;
        padding: 5px 1px;
      }
      &:nth-child(3) {
        width: auto;
        color: @colorPrimary;
        font-weight: bold;
        text-align: left;
        padding: 5px 3px;
      }

      &:last-child {
        width: 10%;
      }
    }

    > tbody > tr:nth-of-type(even) > td {
      background: #eff7fa;

      &:last-child {
        background: none;
      }
    }
  }

  &__trend {
    font-size: 18px;

    &--up {
      color: @efficiencyOfWork__green;
      transform: rotate(-45deg);
    }

    &--down {
      color: @efficiencyOfWork__red;
      transform: rotate(45deg);
    }
  }

  &__gauge {
    &__title {
      position: absolute;
      top: 158px;
      min-width: 350px;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;

      &--missing {
        top: 80px;
        color: @efficiencyOfWork__red;
        font-size: 35px;
        font-weight: bold;
        line-height: 32px;
      }
    }

    &__chart {
      height: 250px;
      width: 370px;
      margin: auto;
    }
  }

  .amChartsPanel, .amcharts-chart-div, svg {
    overflow: visible !important;
  }

  .amcharts-guide {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: bold;
    fill: #9d9d9d;
  }
}
