body.main {

}

#container {
    position: relative;
    margin: 0;
    margin-top: @header + 65px;
    margin-left: 0;
    padding: 0;
    min-height: calc(~"100vh - 138px");
}
#container > .backdrop {
    cursor: pointer;
}

#page-container {
    padding: @indent;
}
@media (max-width: @screen-xs-max) {
    #page-container {
        padding: @indent 7px;
    }
}

/* Page Header */
#page-header {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 1025;

    .sidebar-toggle {
        position: absolute;
        top: 13px;
        left: 0;
        display: block;
        height: 34px;
        width: 40px;
        text-align: center;
        color: @colorInfo;
        font-size: 25px;
        font-weight: 700;
    }
}
.page-title {
    position: relative;
    height: 65px;
    padding: 5px 0 5px 55px;
    color: #000;
    background: #fff;
    //background: linear-gradient(to right, #fff 25%, fade(#fff, 25%));
    box-shadow: 0 3px 3px 0 fade(#888, 50%);
    .clearfix();

    h1 {
        margin: 0;
        padding: 12px 0;
        color: inherit;
        font-size: 25px;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;

        small {
            font-size: 15px;
            color: #444;
        }
    }
}

.quick-info.row>div {
    padding-right: 25px;
}
.quick-info h3 {
    margin: 3px 0;
}
.quick-info span {
    color: #aaa;
}
.flashMessagesTop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1045;
}

.backdrop {
    position: fixed !important;
}

.modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-header {
    background: @colorPrimary;
    color: #fff;
}
.modal-header .close {
    color: #fff;
    opacity: .6;
}

/* Responsive */
@media (min-width: @screen-sm-min) {
    .flashMessagesTop {
        margin-left: 12.5%;
        width: 75%;
    }
}

@media (max-width: @screen-xs-max) {
    #page-header {
        display: none;
    }
    #container {
        margin-top: 40px;
        min-height: calc(~"100vh - 73px");
    }
}

@media (min-width: @screen-md-min) {
    #container {
        margin-left: @sidebarMini;
    }
    .container-limited {
        margin: auto;
        max-width: 1200px;
    }
}

@media (min-width: @screen-lg-min) {
    .flashMessagesTop {
        margin-left: 25%;
        width: 50%;
    }
}

.container-hero {
    margin: auto;
    margin-top: -50px;
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
    text-align: center;
}
