@prediction__blue: #24ace3;
@prediction__pink: #ff7bad;
@prediction__green: #aed685;
@prediction__brown: #c9b39a;
@prediction__height: 500px;

@panel-pink-border: @prediction__pink;
@panel-pink-text: #fff;
@panel-pink-heading-bg: @prediction__pink;

.prediction {
  padding: 8px;

  &__title {
    color: @colorPrimary;

    &--small {
      padding-left: 15px;
      font-size: 85%;
      font-weight: normal;
    }
  }

  &__chart {
    height: @prediction__height;
    margin-bottom: 20px;
    margin-right: 150px;
  }

  &__zoomButtons {
    text-align: right;
    margin-right: 150px;
  }

  &__actionButtons {
    text-align: right;
    margin-bottom: 20px;
  }

  &__modals {
    position: relative;

    .panel {
      display: none;
      position: absolute;
      z-index: 1010;
      width: 350px;
      height: 180px;
      border: 0;
      border-radius: 0;
      .box-shadow(0 2px 6px rgba(0,0,0,.5));

      .panel-heading {
        padding: 7px 15px;
        font-weight: bold;
        border-radius: 0;
      }

      .panel-body {
        overflow: auto;
        max-height: 82%;
      }
    }

    &__close {
      position: absolute;
      padding: 5px 15px;
      font-size: 17px;
      cursor: pointer;
      right: 0;
      top: 0;
    }

    &__subheader {
      display: flex;
      margin-bottom: 10px;

      > :first-child {
        flex: 1;
        text-align: left;
      }

      > :last-child {
        text-align: right;
      }

      i {
        font-size: 17px;
        color: #aaa;
      }
    }
  }

  &__amounts {
    position: relative;
    height: 0;

    &__item {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      font-weight: bold;
      font-size: 17px;
      text-align: right;

      small {
        display: block;
        font-weight: normal;
      }
    }
  }

  &__settings {
    legend {
      display: none;
    }
    fieldset {
      background: #D8EEF6;
      padding: 15px 7px;
      padding-bottom: 0;
      margin-bottom: 15px;
      label {
        margin: 0;
        padding: 6px 0;
      }
    }

    &__selection {
      padding: 0 7px;
      margin-bottom: 15px;
      .radio {
        display: inline-block;
        margin-right: 10px;
      }
    }

    &--btn6 {
      fieldset {
        &:nth-child(6) {
          background: none;
          margin: 0 -7px;
          padding: 0;
          text-align: right;

          .form-group {
            margin: 0;
          }
        }
      }
    }

    &--btn7 {
      fieldset {
        &:nth-child(7) {
          background: none;
          margin: 0 -7px;
          padding: 0;
          text-align: right;

          .form-group {
            margin: 0;
          }
        }
      }
    }

    .input-group-addon {
      background: none;
      border: none;
      padding: 6px;
      padding-right: 0;
      min-width: 41px;
      text-align: left;
    }
  }
}

.prediction-blue {
  color: @prediction__blue;
}
.prediction-brown {
  color: @prediction__brown;
}
.prediction-green {
  color: @prediction__green;
}
.prediction-pink {
  color: @prediction__pink;
}

.panel-pink {
  .panel-variant(@panel-pink-border; @panel-pink-text; @panel-pink-heading-bg; @panel-pink-border);
}
