@fontsPath: "../../fonts";

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'),
        url('@{fontsPath}/OpenSans/OpenSans-Light.woff2') format('woff2'), /* Super Modern Browsers */
        url('@{fontsPath}/OpenSans/OpenSans-Light.woff') format('woff'), /* Pretty Modern Browsers */
        url('@{fontsPath}/OpenSans/OpenSans-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'),
        url('@{fontsPath}/OpenSans/OpenSans.woff2') format('woff2'), /* Super Modern Browsers */
        url('@{fontsPath}/OpenSans/OpenSans.woff') format('woff'), /* Pretty Modern Browsers */
        url('@{fontsPath}/OpenSans/OpenSans.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('@{fontsPath}/OpenSans/OpenSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
        url('@{fontsPath}/OpenSans/OpenSans-Bold.woff') format('woff'), /* Pretty Modern Browsers */
        url('@{fontsPath}/OpenSans/OpenSans-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}
