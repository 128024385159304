/* FA */
.fa-sum:before {
    content: "\03a3";
    font-family: sans-serif;
    font-weight: 700;
    font-size: 110%;
}
.fa-one-quart:before {
    content: "1/4";
    font-family: sans-serif;
    font-weight: 700;
}

/* NProgress */
#nprogress .spinner {
    display: none;
}
#nprogress .peg {
    display: none;
}
#nprogress .bar {
    background: fade(#fff, 75%);
    height: @header + 1px;
    z-index: 1050;
}

/* Datepicker */
.datepicker {
    &.datepicker-dropdown {
        padding: 10px !important;
    }
}

.dateRange {
    .btn;
    .btn-primary;
    display: inline-block;
}

/* SelectPicker */
.form-inline .bootstrap-select {
    width: auto !important;
}

/* Toggle */
.toggle {
    display: inline-block;
    cursor: pointer;
    padding: 5px;

    .toggle-icon {
        display: inline-block;
    }

    .toggle-true,
    .toggle-false {
        height: 20px;
        width: 20px;
        padding: 3px 0 0;
        border-radius: 100%;
    }

    .toggle-false {
        color: transparent;
        border: 1px solid #444;
    }

    .toggle-true {
        color: #fff;
        background: @colorSuccess;
    }

    .toggle-label {
        display: inline-block;
        line-height: 21px;
    }
}
