#sidebar {
    display: block;
    position: fixed;
    top: 105px;
    bottom: 0;
    left: -@sidebar;
    width: @sidebar;
    z-index: 1025;
    color: #fff;
    background: @colorPrimary;
    .transition(all .3s ease-in-out);

    .nav-sidebar {
        background: @colorInfo;
    }
}
.sidebar-showed #sidebar {
    left: 0;
}
.sidebar-showed > .backdrop {
    display: block;
}

.sidebar-brand {
    display: block;
    padding: 16px 6px;
    background: #fff;
}

.nav-header {
    margin: 0;
    padding: 10px;
    line-height: 15px;
    background: @colorPrimary;
    color: #fff;
    text-transform: uppercase;
}

.nav-sidebar > li > a {
    padding: 8px;
    line-height: 18px;
    color: #fff;
}
.nav-sidebar > li > a:hover, .nav-sidebar > li > a:focus, .nav-sidebar > li > a.active {
    color: #fff;
    background-color: fade(#000, 15%);
}
.nav-sidebar .open > a, .nav-sidebar .open > a:hover, .nav-sidebar .open > a:focus {
    color: #fff;
    background-color: fade(#000, 15%);
}
.nav-sidebar .nav-menu {
    display: none;
    padding-left: 22px;
}
.nav-sidebar .open .nav-menu {
    display: block;
}


.nav-sidebar > li > a > .nav-item-icon {
    padding-right: 5px;
}

#sidebar .tooltip {
    display: none !important;
}

/* Mini */
#sidebar-mini {
    display: none;
    position: fixed;
    top: 105px;
    bottom: 0;
    left: 0;
    width: @sidebarMini;
    z-index: 1025;
    color: #fff;
    background: @colorPrimary;
}

#sidebar-mini .scroll {
    background: @colorInfo;
}

#sidebar-mini .sidebar-brand {
    height: 160px;
    padding: 20px 3px;
    background: #fff;
    img {
        transform: rotate(-90deg);
        height: 23px;
        width: auto;
        position: absolute;
        top: 116px;
        left: -52px;
    }
}
#sidebar-mini .triangle-brand {
    height: 0;
    width: 0;
    margin-bottom: 10px;
    border-style: solid;
    border-width: (@sidebarMini/1) @sidebarMini 0 0;
    border-color: #fff @colorInfo transparent transparent;
    &.end {
        border-color: @colorInfo @colorPrimary transparent transparent;
    }
}

#sidebar-mini .divider {
    display: block;
    height: 36px;
    background: transparent;
}

#sidebar-mini .caret {
    display: none;
}

#sidebar-mini .nav-header {
    display: none;
}

#sidebar-mini .scroll > ul > li > a {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
#sidebar-mini .scroll > ul > li > a > .nav-item-text {
    display: none;
}
#sidebar-mini .scroll > ul > li > a > .nav-item-icon {
    padding: 0;
    font-size: 135%;
}

#sidebar-mini .nav-menu {
    display: none;
    position: fixed;
    min-width: @sidebar - @sidebarMini;
    padding: 0;
    margin-top: -10px;
    left: @sidebarMini;
    background: @colorInfo;
}

#sidebar-mini .scroll > ul > li:hover > a {
    color: #fff;
    background-color: @colorPrimary;
}
#sidebar-mini .scroll > ul > li:hover > a > .nav-item-text {
    display: block;
    position: fixed;
    min-width: @sidebar - @sidebarMini;
    text-align: left;
    left: @sidebarMini;
    margin-top: -28px;
    color: #fff;
    background-color: @colorInfo;
    padding: 9px 12px;
}
#sidebar-mini .scroll > ul > li:hover .nav-menu {
    display: block;
}

@media (min-width: @screen-md-min) {
    #sidebar-mini {
        display: block;
    }
}

@media (max-width: @screen-xs-max) {
    #sidebar {
        top: 40px;
    }
}
