/* Header - Nav */
.energy-header {
    margin: 0;
    padding: 0 @indent;
}
.energy-header > h3 {
    margin: 0;
    float: left;
    line-height: 40px;
}
.energy-btn-edit {
    position: absolute;
    top: 0;
    right: 42px;
    color: #444 !important;
    background: #fff !important;
    padding: 0 8px;
    font-size: 20px;
    line-height: 40px;
}
.energy-btn-toggler {
    z-index: 5;
    float: right;
}
.energy-btn-toggler > button {
    color: #fff !important;
    background: #444 !important;
    padding: 0 8px;
    font-size: 20px;
    line-height: 40px;
}
#energyClientModal {}
#energyClientModal .modal-dialog {
    max-width: 450px;
}

/* QuickInfo */
.energy-quickinfo {
    margin: 0 0 @indent;
}
.energy-quickinfo-item {
    text-align: center;
}
.energy-quickinfo-item h4 {
    margin: 0;
    display: inline-block;
    margin: 5px 10px;
}
.energy-quickinfo-title {
    margin: 0;
    font-size: 18px;
    text-decoration: underline;
}
.energy-quickinfo-icon {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 29px;
    padding: 0;
    font-size: 21px;
    color: #fff;
    background: #444;
    border-color: none;
    .border-radius(100%);
}

/* TimeCompare */
.timeCompareRow {
    display: inline-block;
    position: relative;
    margin: 0;
    margin-right: 5px;
    width: 250px;
}
.timeCompareRow input {
    width: 100%;
}
.compare-daterange {
    min-width: 180px;
}

/* Heatmap */
.heatmap {
    display: block;
    margin: 0 auto;
    font-size: 7px;
}
.heatmap-row {
    display: block;
    padding: 0;
    margin: 0;
    height: 27x;
}
.heatmap-row:before {
    content: " ";
    display: table;
}
.heatmap-row:after {
    clear: both;
    content: " ";
    display: table;
}
.heatmap-cell {
    background: #FFFFFF;
    width: 28px;
    height: 28px;
    line-height: 28px;
    display: inline-block;
    border: 1px solid #fff;
    text-align: center;
    margin: 0 0 0 -5px;
    padding: 0;
}
.heatmap-note {
    width: 35px;
}
.heatmap-legend {
    display: inline-block;
    width: 50px;
    height: 40px;
    margin: 0 0 0 -5px;
    text-align: center;
}
.heatmap-cell-sum {
    background: #888;
    color: #eee;
}
@media (min-width: 1000px) {
    .heatmap {
        font-size: 9px;
    }
    .heatmap-row {
        height: 33px;
    }
    .heatmap-cell {
        width: 34px;
        height: 34px;
        line-height: 34px;
    }
    .heatmap-note {
        width: 45px;
    }
    .heatmap-legend {
        width: 65px;
    }
}
@media (min-width: 1400px) {
    .heatmap {
        font-size: 11px;
    }
    .heatmap-row {
        height: 40px;
    }
    .heatmap-cell {
        width: 41px;
        height: 41px;
        line-height: 41px;
    }
    .heatmap-note {
        width: 55px;
    }
    .heatmap-legend {
        width: 80px;
    }
}
