.table-flex {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: block;
    position: relative;
    background: #fff;
    margin: 0 0 15px;
    .box-shadow(1px 5px 5px 1px #ccc);
}

.table-flex .table-row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: -1px;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0;
    }
}

.table-flex .table-cell {
    flex-grow: 1;
    min-height: 1px;
    border: 1px solid #ddd;
    padding: 8px;

    & + .table-cell {
        border-left: 0;
    }
}

.table-flex.table-hover {
    .table-row:hover .table-cell {
        background: #f5f5f5;
    }
}
