.clientSelectContext {
    position: fixed;
    top: 40px;
    left: 0;
    z-index: 1025;
    width: 100%;

    .btn {
        color: @brand-info !important;
        padding-left: 30px;
        opacity: 1;
    }

    > i {
        position: absolute;
        left: 8px;
        font-size: 18px;
        line-height: 32px;
        color: @brand-info;
        z-index: 5;
    }
}

@media (min-width: @screen-sm-min) {
    .clientSelectContext {
        top: 56px;
        left: auto;
        right: 15px;
        width: auto;
        .fSDropdown {
            right: 0;
            left: auto;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .clientSelectContext {
        .fSGroup {
            width: 100vw;
        }
    }
}
