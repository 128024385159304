.fSGroup {
    .btn {
        padding-left: 5px;
        min-width: 120px;
        width: 100%;
    }
    .fSSearch input {
        width: 100%;
    }
    .fSDropdown {
        border-radius: 0;
        .list-group-item {
            white-space: normal;
        }
    }
}

@media (min-width: @screen-sm-min) {
    .fSGroup {
        .fSDropdown {
            min-width: 320px;
        }
    }
}

.f-autocompleter {
    box-shadow: none;
    .f-autocompleter-inputWrap {
        .f-autocompleter-input {
            .form-control;
        }
        .f-autocompleter-dropdownToggle {
            padding: 5px 10px;
            border: 1px solid #ccc;
            border-left: none;
        }
    }
    .f-autocompleter-menu {
        height: auto;
        max-height: 280px;
        resize: none;
        border: 1px solid #ccc;
        .f-autocompleter-menu__item {
            border: none !important;
            padding: 8px 12px;
            &:hover {
                color: #333;
                background-color: #e6e6e6;
            }
        }
        .f-autocompleter-menu__item--selected {
            color: #fff;
            background-color: @brand-primary !important;
        }
    }
}

fehu-confirm {
    .fconfirm {
        #fcmbox {
            position: fixed;
            top: 50vh;
            left: 50vw;
            color: #222;
            .fcmbox__actions {
                .btn {
                    .btn;
                }
                .btn.primary {
                    .btn-primary;
                }
                .btn.danger {
                    .btn-default;
                }
            }
        }
    }
}

.has-error {
    .fSGroup .btn {
        border-color: @brand-danger;
    }
    .f-autocompleter-input {
        border-color: @brand-danger !important;
    }
    .f-autocompleter-dropdownToggle {
        border-color: @brand-danger !important;
    }
}
.has-success {
    .fSGroup .btn {
        border-color: @brand-success;
    }
    .f-autocompleter-input {
        border-color: @brand-success !important;
    }
    .f-autocompleter-dropdownToggle {
        border-color: @brand-success !important;
    }
}
.has-warning {
    .fSGroup .btn {
        border-color: @brand-warning;
    }
    .f-autocompleter-input {
        border-color: @brand-warning !important;
    }
    .f-autocompleter-dropdownToggle {
        border-color: @brand-warning !important;
    }
}

.kenaz-datetime-picker #heading {
    padding: 7px 15px;
}
.kenaz-datetime-picker #heading .date {
    margin-bottom: 0;
}
.kenaz-datetime-picker #heading .year {
    display: block;
}
.kenaz-datetime-picker.modal {
    position: fixed;
    top: 31px;
}
.kenaz-datetime-picker.modal .modal-dialog {
    height: auto;
    margin-top: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.kenaz-datetime-picker .componentContainer {
    height: auto;
}
.kenaz-datetime-picker #heading .closeBtn {
    top: 7px;
    right: 7px;
}

kenaz-time-select .timeselect {
    padding: 7px 0;
}
kenaz-time-select .timeselect .timeselect__container .timeselect__arrow {
    display: none;
}
kenaz-time-select .timeselect .timeselect__container .timeselect__num {
    border: 1px solid #ccc;
    padding-left: 25%;
    padding-right: 10px;
    box-shadow: none;
}
