.nav-tabs {
    height: 41px;
    border: none;
    border-bottom: 1px solid #ddd;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 15px;

    > li {
        float: none;
        display: inline-block;

        > a {
            margin: 0;
            border: none;
            color: #222;
            border-bottom: 2px solid transparent;
            &:hover, &:focus {
                color: #222;
                background: none;
                border: none;
                border-bottom: 2px solid #ddd;
            }
        }

        &.active > a {
            color: #222;
            font-weight: 700;
            border: none;
            &:hover, &:focus {
                border: none;
            }
        }
    }
}
