/* Dropdown */
.dropdown-menu {
    margin: 0;
    padding: 0;
    .border-radius(0px);
    .box-shadow();
}
.dropdown-header {
    padding: 7px;
    background: #e0e0e0;
    color: #444;
    font-size: 15px;
    font-weight: 400;
}
.dropdown-menu > li > a {
    padding: 5px 10px;
    font-size: 15px;
    line-height: 25px;
}
.dropdown-menu > li > a > i {
    color: #e0e0e0;
    margin: 5px 0 0 0;
}
.dropdown-menu > li:hover > a {
    color: #fff;
    background: @colorPrimary;
}
.dropdown-menu > li:hover > a > i {
    color: #fff;
}
.dropdown-menu .divider {
    margin: 2px 10px;
}

/* Breadcrumbs */
.breadcrumb {
    margin: 0;
    padding: 0 @indent;
    color: #444;
    line-height: 40px;
    background: transparent;
}
.breadcrumb > li > a {
    color: inherit;
}
.breadcrumb > li + li:before {
    content: "\003E";
    color: inherit;
}
.breadcrumb > li > span {
    font-weight: 700;
}

.nav-breadcrumb {
    padding: 6px 0;
}
.nav-breadcrumb > .breadcrumb {
    margin: 0;
}

/* Pagination */
.pager {
    margin: 0 0 @indent;
}
.pager>li>a:hover, .pagination>li>a:hover {
    color: #fff;
    background-color: @colorPrimary;
    border-color: @colorPrimary;
}
.pager>li.disabled>a:hover {
    border-color: #ddd;
}
.pagination {
    margin: 0 0 @indent;
}
.pagination>li>a, .pagination>li>span {
    color: @colorPrimary;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    .border-radius(0);
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: @colorPrimary;
}
.pagination>li:last-child>a, .pagination>li:last-child>span {
    .border-radius(0);
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    .border-radius(0);
}

/* Pills */
.nav-pills > li > a {
    .border-radius(0);
}
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: @colorPrimary;
}
