.welcome {
  padding: 1px 30px;

  .background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      height: 100%;
      width: auto;
      min-width: 100%;
    }
  }

  h1 {
    color: #fff;
    margin: 30px 0 0;
    font-weight: 300;
    text-transform: uppercase;
  }

  .help {
    float: right;
    padding-bottom: 30px;

    .arrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 30px 30px 30px;
      border-color: transparent transparent @colorPrimary transparent;
    }
    .arrow-wrapper {
      float: right;
    }
    .content {
      display: flex;
      color: #fff;

      .left {
        background: @colorInfo;
        padding: 25px 10px;
        font-size: 30px;
        .border-left-radius(4px);
      }
      .body {
        background: @colorPrimary;
        padding: 25px 20px;
        font-size: 16px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  .welcome {
    .help {
      float: left;
      .arrow-wrapper {
        float: left;
      }
    }
  }
}
