.maintenance {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background: white;

    .sign-page {
        box-shadow: 6px 4px 9px 0px #444;
    }

    .maintenance-heading {
        .sign-page-title;
        font-weight: bold;
        letter-spacing: 3px;
        text-align: center;
        font-size: 18px;
        height: auto;
        text-transform: uppercase;
    }

    .maintenance-message {
        line-height: 25px;
        font-size: 15px;
        position: relative;
        padding: 30px;
        background: #fff;
        font-weight: 500;
    }

    .maintenance-greeting, .maintenance-closing {
        display: block;
    }

    .maintenance-greeting {
        padding-bottom: 10px;
    }

    .maintenance-closing {
        padding: 30px 0;
    }
}
