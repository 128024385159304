@modal-blue: #019bcd;
@modal-red: #da0605;
@modal-green: #00973f;
@modal-orange: #f59105;
@modal-darkblue: #2a217c;
@modal-pink: #d70874;
@modal-gray: #909193;
@modal-violet: #6f5499;
@modal-darkgreen: #109096;

.text-blue {
    color: @modal-blue;
}
.text-red {
    color: @modal-red;
}
.text-green {
    color: @modal-green;
}
.text-orange {
    color: @modal-orange;
}
.text-darkblue {
    color: @modal-darkblue;
}
.text-pink {
    color: @modal-pink;
}
.text-gray {
    color: @modal-gray;
}
.text-violet {
    color: @modal-violet;
}
.text-darkgreen {
    color: @modal-darkgreen;
}

#chart, .chart {
    height: 320px;
    width: 100%;
}

.chart-iframe {
    height: 320px;
    width: 100%;
    border: 0;
}

.chart-subheader {
    .modal-header();
    color: inherit;
    background: #eff7fa;
    margin-bottom: 15px;
    border-bottom: 1px solid #dee2e5;

    .modal-title {
        display: inline-block;
        position: relative;
        top: 2px;
        font-weight: 700;
        line-height: 30px;
        padding-left: 15px;
    }
    small {
        position: relative;
        top: -1px;
    }
}

.TP {
    width: 70%;
    margin: 0 auto;
    th{
        text-align: center;
        border: 2px #2f75b5 solid;
        background: #ddebf7 !important;
        &:first-child{
            padding-right: 5px;
        }
    }
    td{
        text-align: center;
        border: 2px #2f75b5 solid;
        &:first-child{
            text-align: center;
            border: none;
        }
    }
}

@media print {
    .chart-subheader {
        display: none !important;
    }

    .reductorAnalyze {

        .text-primary {
            color: @colorPrimary!important;
        }

        .text-danger {
            color: @colorDanger!important;
        }


    }
}



.dialog {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1080;
}

.modal-chart {
    .modal-dialog {
        width: 100%;
        margin: 0;
        margin-top: 7px;
        margin-left: 7px;
    }
    .modal-header {
        padding: 0;
        color: #444;
        background: #fff;
        border: 0;

        .modal-title {
            line-height: 40px;
            padding: 0 15px;
        }
        .modal-btn {
            .btn();
            height: 40px;
            padding: 5px 15px;
            border: 0;
            color: #444;
            background: #fff;
            opacity: 0.5;
            &:hover {
                color: #fff;
                background: #444;
            }
            &:focus {
                outline: 0;
            }
            .fa {
                position: relative;
                font-size: 20px;
                width: 17px;
                vertical-align: middle;
            }
        }
        .modal-btn-close {
            opacity: 1;
            font-size: 125%;
            width: 45px;
        }
    }
    .modal-body {
        padding: 0;
    }
}

.modal-chart-color(@color) {
    .modal-header {
        color: #fff;
        background: @color;
        .modal-btn {
            color: #fff;
            background: transparent;
            padding: 5px 12px;
            width: 40px;
            &:hover {
                opacity: 1;
            }
        }
        .modal-btn-close {
            background: tint(@color, 25%);
            &:hover {
                background: transparent;
            }
        }
    }
}
.modal-chart.modal-blue {
    .modal-chart-color(@modal-blue);
}
.modal-chart.modal-red {
    .modal-chart-color(@modal-red);
}
.modal-chart.modal-green {
    .modal-chart-color(@modal-green);
}
.modal-chart.modal-orange {
    .modal-chart-color(@modal-orange);
}
.modal-chart.modal-darkblue {
    .modal-chart-color(@modal-darkblue);
}
.modal-chart.modal-pink {
    .modal-chart-color(@modal-pink);
}
.modal-chart.modal-gray {
    .modal-chart-color(@modal-gray);
}
.modal-chart.modal-violet {
    .modal-chart-color(@modal-violet);
}
.modal-chart.modal-darkgreen {
    .modal-chart-color(@modal-darkgreen);
}

.modals-panel {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 30px;
    width: 100%;
    z-index: 1040;
    padding: 0 10px;
    margin: 0 0 10px;
    .m-panel {
        float: right;
        position: relative;
        width: 180px;
        line-height: 20px;
        padding: 0;
        padding-right: 29px;
        margin-left: 2px;
        cursor: pointer;
        color: #fff;
        background: @modal-blue;
        .border-radius(4px);
        &:hover {
            opacity: 0.5;
        }
        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 9px 7px;
            background: rgba(255,255,255,.5);
        }
        .text-block {
            display: block;
            padding: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
@media (max-width: @screen-sm-max) {
    .modals-panel {
        display: none;
    }
}

.m-panel.m-panel-blue {
    background: @modal-blue;
}
.m-panel.m-panel-red {
    background: @modal-red;
}
.m-panel.m-panel-green {
    background: @modal-green;
}
.m-panel.m-panel-orange {
    background: @modal-orange;
}
.m-panel.m-panel-darkblue {
    background: @modal-darkblue;
}
.m-panel.m-panel-pink {
    background: @modal-pink;
}
.m-panel.m-panel-gray {
    background: @modal-gray;
}
.m-panel.m-panel-violet {
    background: @modal-violet;
}
.m-panel.m-panel-darkgreen {
    background: @modal-darkgreen;
}
