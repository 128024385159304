/* Backdrop */
.backdrop {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  width: 100%;
  height: 100%;
  background: fade(#000, 66%);
}

.backdrop.active {
  display: block;
}

/* Background */
.bg-primary {
  background-color: @colorPrimary;
}

.bg-success {
  background-color: @colorSuccess;
}

.bg-info {
  background-color: @colorInfo;
}

.bg-warning {
  background-color: @colorWarning;
}

.bg-danger {
  background-color: @colorDanger;
}

/* Text */
.text-primary {
  color: @colorPrimary;
}

.text-info {
  color: @colorInfo;
}

.text-success, .text-green {
  color: @colorSuccess;
}

.text-warning {
  color: @colorWarning;
}

.text-danger, .text-red {
  color: @colorDanger;
}

/* Identation */
.remove-margin {
  margin: 0 !important;
}

.indented {
  margin-bottom: @indent;
}

.margin-v {
  margin: @indent 0;
}

.margin-h {
  margin: 0 @indent;
}

.margin-top {
  margin-top: @indent;
}

.margin-bottom {
  margin-bottom: @indent;
}

.margin-left {
  margin-left: @indent;
}

.margin-right {
  margin-right: @indent;
}

/* Responsive */
.row {
  margin: 0 -7px;
}

[class*="col-"] {
  padding: 0 7px;
}

.col-left, .col-middle, .col-right {
  text-align: center;
}

@media (min-width: @screen-md-min) {
  .col-left {
    text-align: left;
  }

  .col-right {
    text-align: right;
  }
}

/* Submenu */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin: 0;
}
.dropdown-submenu > .dropdown-grip {
  position: absolute;
  top: -20px;
  left: 100%;
  height: 20px;
  width: 100%;
  background: transparent;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu.showIcon > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 7px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}

.dropdown-submenu.dropdown-submenu-right {

}

.dropdown-submenu.dropdown-submenu-right > .dropdown-menu {
  left: -100%;
}
.dropdown-submenu.dropdown-submenu-right > .dropdown-grip {
  left: -100%;
}

@media (max-width: @screen-sm-max) {
  .dropdown-grip {
    display: none;
  }
  .dropdown-submenu.dropdown-submenu-right>.dropdown-menu {
    display: block;
    position: relative;
    left: 0;
    padding-left: 15px;
    margin-bottom: 15px;
  }
}

.rounded {
  border-radius: 4px;
}
