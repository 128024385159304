.sign-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
        min-height: 100%;
        min-width: 100%;
    }
}

.sign-page {
    margin: 0 auto;
    max-width: 500px;
    .box-shadow(2px 2px 10px 2px #444);

    .sign-block {
        position: relative;
        padding: @indent;
        background: #fff;
    }
}

.sign-page-title {
    display: block;
    height: 80px;
    padding: 25px;
    background: @colorInfo;
    color: #fff;
    img {
        display: block;
        width: auto;
        height: 30px;
    }
    .title {
        font-size: 14px;
        padding: 5px 0;
        text-transform: uppercase;
        display: inline-block;
        color: #fff;
    }
}

/* Responsive */
@media (max-width: @screen-xs-max) {
    body.sign {
        background: @colorInfo;
    }
}
@media (min-width: @screen-sm-min) {
    .sign-page {
        margin: 0 auto;
        margin-top: calc(~"50vh - 150px");
    }
}

/* Sign Pages */
.sign-in-actions {
    font-size: 14px;
    padding: @indent 0 0;
    margin: 0;
    text-align: center;
}

/* Sign Forms */
.form-sign {
    > .form-group:last-child {
        margin-bottom: 0;
    }
    .form-control {
        line-height: 40px;
        height: 40px;
        border: none;
        box-shadow: none !important;
    }
    .input-group-addon {
        border: none;
        border-radius: 100%;
        padding: 0 7px;
        font-size: 20px;
        color: #fff;
        background-color: @colorPrimary;
    }
    .form-control-container {
        padding-left: @indent;
    }
    .btn {
        line-height: 40px;
        font-size: 18px;
        float: right;
    }
    .btn-default {
        float: left;
    }
    .btn-sign-in {
        display: block;
        width: 100%;
        float: none;
    }
}

.sign-back {
    text-align: center;
    margin: 20px;
}

@media (min-width: @screen-sm-min) {
    .sign-back {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

.sign-footer {
    position: absolute;
    bottom: 50px;
    left: 50px;

    h3 {
        color: #fff;
        margin: 0px;
        font-size: 30px;
    }

    .twoline {
        display: inline-block;
        text-align: right;
        font-size: 14px;
        line-height: 13px;
    }
}

@media (max-width: @screen-xs-max) {
    .sign-footer {
        position: relative;
        text-align: center;
        left: auto;
        top: 25px;
        width: auto;
        margin: auto;

        .twoline {
            display: block;
            text-align: center;
            margin-bottom: 15px;
        }

        .phoneline {
            display: block;
            margin-bottom: 15px;
        }
    }
}
