/* Navbar */
@navbar-height: 40px;

@navbar-inverse-color: #fff;
@navbar-inverse-bg: @colorInfo;
@navbar-inverse-link-color: #fff;

.navbar-nav {
    > li > a {
        font-size: 16px;
        opacity: .66;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}

#notifiesDropdown {
    .dropdown-menu {
        min-width: 320px;
    }
}

.navbar-inverse {
    border: none;
    .navbar-brand {
        padding: 10px;
        margin-left: -15px;
    }
}

.nav.navbar-fixed {
    float: right;
    margin: 0 -15px;
    > li {
        float: left;
        > a {
            height: @navbar-height;
            color: #fff;
            font-size: 16px;
            opacity: .66;
            background: none;
            &:hover, &:focus {
                opacity: 1;
            }
        }
        &.open > a {
            background: @colorPrimary;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .navbar-inverse {
        .navbar-brand {
            position: absolute;
            left: calc(~"50vw - 63px");
        }
    }
}
