/* Blocks */
.block {
    display: block;
    position: relative;
    padding: @indent;
    margin: 0;
    margin-bottom: @indent;
    background: #fff;
    .box-shadow(1px 1px 5px 1px #ccc);
    .clearfix();
}
.block-nav {
    margin: -@indent;
    margin-bottom: @indent;
    padding: 3px 3px 0;
    color: #fff;
    background: @colorDefault;
}
.block-nav .nav-tabs > li > a {
    color: #fff;
    margin-right: 3px;
    border: 1px solid @colorDefault;
    border-bottom-width: 0;
}
.block-nav .nav-tabs > li:last-child > a {
    margin-right: 0;
}
.block-nav .nav-tabs > li:hover > a, .block-nav .nav-tabs > li > a:focus {
    color: #fff;
    background: @colorDefault - #111;
    border-color: #fff;
}
.block-nav .nav-tabs > li.active > a {
    color: @colorDefault;
    background: #fff;
    border-color: #fff;
}

/* Widgets */
.widget {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    margin-bottom: @indent;
    color: initial;
    background: #fff;
    .box-shadow(1px 1px 5px 1px #ccc);
    .clearfix();
}
.widget:hover, .widget:focus {
    color: initial;
    text-decoration: none;
}
.widget-header {
    position: relative;
    margin: 0;
    padding: 0 @indent;
    color: #fff;
    background: @colorPrimary;
}
.widget-header > h3 {
    margin: 0;
    line-height: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: 100;
}
.widget-options {
    position: absolute;
    right: @indent;
    top: 0;
}
.widget-body {
    padding: @indent;
}

/* Widget buttons and toggler */
.widget-btn {
    line-height: 40px;
}

/* Widget links */
.widget-hover:hover {
    .box-shadow(0 0 10px 5px rgba(150,150,150,.5));
}
.widget-simple {
    padding: 10px;
}
.widget-simple:hover {
    .box-shadow(0 0 10px 5px rgba(150,150,150,.5));
}
.widget-simple > h3 {
    color: @colorPrimary;
    margin: 10px 0;
    font-size: 20px;
    max-height: 44px;
    overflow: hidden;
}
.widget-simple > .widget-icon {
    float: left;
    width: 64px;
    height: 64px;
    text-align: center;
    .border-radius(100%);
}
.widget-simple > .widget-icon > i {
    color: #fff;
    font-size: 40px;
    line-height: 64px;
}

/* Block Slideshow */
.slideshow {
    position: fixed;
    top: 0;
    z-index: -1;
    height: 450px;
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        min-width: 1920px;
        height: auto;
        position: relative;
        top: -450px;
    }
    .img-glow {
        position: absolute;
        height: 0;
        width: 100%;
        bottom: 0;
        box-shadow: 0 0 25px 50px #fff;
    }
}

/* Cardboard */
.cardboard {
    position: relative;
    background: #eee;
    margin: 0;
    margin-bottom: 15px;
    padding: 15px;
    padding-top: 60px;
    &:before {
        content: " ";
        display: block;
        width: 100%;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        background: #D71921;
    }
}
.cardboard-heading {
    position: absolute;
    color: #fff;
    top: 15px;
    left: 15px;
    font-size: 21px;
}
.cardboard-icon {
    .cardboard-heading;
    left: inherit;
    top: 0;
    right: 15px;
    font-size: 45px;
    line-height: 60px;
    & > * {
        font-size: 45px !important;
        line-height: 60px !important;
    }
}
/* Cards */
.card {
    position: relative;
    padding: 15px;
    margin-bottom: 15px;
    background: #fff;
    .box-shadow(0 5px 5px 0 #ccc);
}
.card {
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin: 5px 0;
    }
    hr {
        margin: 10px 0;
    }
    .text-label {
        font-size: 21px;
        color: #D71921;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.card-table {
    margin: 10px 0;
    .table {
        margin: 0;
    }
}
.card-btn-toggle {
    .btn;
    .btn-link;
    color: #777;
    padding: 0;
    line-height: 15px;
    outline: none !important;
    &:focus, &:hover {
        text-decoration: none;
        color: #444;
    }
}
.cardboard-default {
    &:before {
        background: #444;
    }
    .card .text-label {
        color: #444;
    }
}
.cardboard-primary {
    &:before {
        background: @btn-primary-bg;
    }
    .card .text-label {
        color: @btn-primary-bg;
    }
}
.cardboard-info {
    &:before {
        background: @btn-info-bg;
    }
    .card .text-label {
        color: @btn-info-bg;
    }
}
.cardboard-warning {
    &:before {
        background: #e67f22;
    }
    .card .text-label {
        color: #e67f22;
    }
}
.cardboard-danger {
    &:before {
        background: #D71921;
    }
    .card .text-label {
        color: #D71921;
    }
}

/**/
.grid-card > div {
    width: 20%;
}

/**/
#offerDialog .modal-dialog {
    margin-top: 6%;
}
#offerDialog .modal-content {
    padding: 10px;
}
#offerDialog .modal-body {
    padding: 30px;
}
#offerDialog .col-sm-5 {
    text-align: right;
    padding: 0;
}
#offerDialog .close {
    position: relative;
    z-index: 5;
}
#offerDialog h3 {
    margin-top: 0;
    margin-bottom: 55px;
    font-weight: 300;
    font-size: 28px;
}
#offerDialog p {
    font-size: 19px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 40px;
}
#offerDialog p b {
    font-weight: 400;
}
#offerDialog .img-inline {
    display: inline-block;
    width: auto;
    height: 40px;
    margin-left: 10px;
}
@media (min-width: 998px) {
    #offerDialog .modal-dialog {
        width: 980px;
    }
}

.btn-group-alt {
    .btn {
        margin: 0;
        margin-right: -3px;
        background: transparent;
        border-color: transparent;
        color: inherit;
        &.active {
            opacity: 1.0;
            color: #fff;
            background: @colorPrimary;
            border-color: @colorPrimary;
        }
    }
}
