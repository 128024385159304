.timeline {
    position: relative;
    margin: -@indent 0;
    padding: @indent 0;
}
.timeline-header {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    padding: 0 15px;
    min-height: 60px;
    line-height: 60px;
    background-color: #ffffff;
    border-bottom: 2px solid #f0f0f0;
    z-index: 500;
}
.timeline-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.timeline-list:after {
    position: absolute;
    display: block;
    width: 2px;
    top: 0;
    left: 95px;
    bottom: 0;
    content: "";
    background-color: #f0f0f0;
    z-index: 1;
}
.timeline-header + .timeline-list:after {
    top: 60px;
}
.timeline-list li {
    position: relative;
    margin: 0;
    padding: @indent 0;
}
.timeline-list.timeline-hover li:hover {
    background-color: #f9f9f9;
}
.timeline-list .timeline-icon {
    position: absolute;
    left: 80px;
    top: 10px;
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 14px;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 15px;
    z-index: 500;
    padding: 1px 10px;
}
.timeline-list .active .timeline-icon, .timeline-list .active .timeline-icon > i {
    background-color: @colorPrimary;
    border: none;
    color: #ffffff;
}
.timeline-list .active .timeline-icon > i {
    color: #ffffff;
}
.timeline-list .timeline-time {
    float: left;
    width: 70px;
    text-align: right;
}
.timeline-list .timeline-content {
    margin-left: 120px;
    padding-right: 10px;
}
.timeline-content a, .timeline-content a > * {
    color: @colorPrimary;
}

@media (max-width: @screen-xs-max) {
    .timeline {
        margin: 0;
        padding: 0;
    }
    .timeline-list:after {
        width: 0;
    }
    .timeline-icon {
        display: none;
    }
    .timeline-time {
        display: none;
    }
    .timeline-content {
        margin-left: 0 !important;
    }
}
