/* Bootstrap Framework */
@import "/node_modules/bootstrap/less/bootstrap.less";

/* Fonts */
@import "/node_modules/font-awesome/less/font-awesome.less";
@import "/node_modules/font-es/less/font-es";
@import "design/fonts.less";

/* Plugins */
@import (inline) "/node_modules/amcharts/style.css";
@import (inline) "/node_modules/amcharts/plugins/export/export.css";
@import (inline) "/node_modules/animate.css/animate.min.css";
@import (inline) "/node_modules/jquery-ui/themes/base/jquery-ui.css";
@import (inline) "/node_modules/nprogress/nprogress.css";
@import (inline) "/node_modules/fullcalendar/dist/fullcalendar.css";

/* Plugins Overwrite */
@import "components/plugins.less";

/* Design */
@import "design/typography.less";
@import "design/buttons.less";
@import "design/forms.less";
@import "design/navigations.less";

/* Layout */
@import "layout/header.less";
@import "layout/content.less";
@import "layout/footer.less";
@import "layout/sidebar.less";
@import "layout/sign.less";
@import "layout/maintenance.less";

/* Components */
@import "components/appui";
@import "components/blocks.less";
@import "components/eventCalendar";
@import "components/fehuui";
@import "components/helpers.less";
@import "components/spinners.less";
@import "components/tables.less";
@import "components/tabs";
@import "components/trapezoid";
@import "components/selectContext";
@import "components/panels";

/* Pages */
@import "pages/timeline.less";
@import "pages/charts.less";
@import "pages/invoicing-place.less";
@import "pages/energy.less";
@import "pages/enmag.less";
@import "pages/layouts-form";
@import "pages/welcome";
@import "pages/efficiencyOfWork";
@import "pages/prediction";

/* Variables */
@import "variables.less";
@import "mixins.less";

[hidden], template {
    display: none !important;
}

.es {
    top: 2px;
    position: relative;
}

fehu-tooltip {
    display: none;
}