/* Controls */
input[type="radio"], input[type="checkbox"] {
    margin-right: 10px;
}
.form-control {
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
    .border-radius(0);
}
textarea.form-control {
    resize: vertical;
    min-height: 100px;
}
.form-control:focus {
    border-color: @colorPrimary;
    .box-shadow-from-to(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px fade(@colorPrimary, 60%));
}
.has-success .form-control {
    border-color: @colorSuccess;
}
.has-success .form-control:focus {
    border-color: @colorSuccess;
    .box-shadow-from-to(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px fade(@colorSuccess, 60%));
}
.has-warning .form-control {
    border-color: @colorWarning;
}
.has-warning .form-control:focus {
    border-color: @colorWarning;
    .box-shadow-from-to(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px fade(@colorWarning, 60%));
}
.has-error .form-control {
    border-color: @colorDanger;
}
.has-error .form-control:focus {
    border-color: @colorDanger;
    .box-shadow-from-to(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px fade(@colorDanger, 60%));
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #fff;
}

/* Groups */
legend {
    border: 0;
    color: @colorPrimary;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}
.form-group {
    .clearfix();
}
.form-group .form-control {
    .border-radius(0);
}
.form-group-sm .form-control {
    .border-radius(0);
}
.form-group-lg .form-control {
    .border-radius(0);
}

/* Input Groups */
.input-group-addon {
    .border-radius(0);
}
.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
    .border-radius(0);
}

/* Helpers */
.help-block {
    margin: 5px 0 0;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
    color: @colorSuccess;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
    color: @colorWarning;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
    color: @colorDanger;
}
.has-success .input-group-addon {
    color: #fff;
    background: @colorSuccess !important;
}
.has-warning .input-group-addon {
    color: #fff;
    background: @colorWarning !important;
}
.has-error .input-group-addon {
    color: #fff;
    background: @colorDanger !important;
}

/* Errors */
form ul.error {
    list-style: none;
    padding: 0;
    margin: 0;
}
form ul.error > li {
    display: block;
    padding: @indent;
    margin: 0;
    margin-bottom: @indent;
    color: #fff;
    background: @colorDanger;
    font-size: 15px;
}

/* Wizard */
.form-wizard-navigation {
    margin: 0;
    margin-bottom: @indent;
}

.form-field {
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    .form-group:last-child {
        margin-bottom: 0;
    }
    .checkbox, .radio {
        display: inline-block;
        margin-left: 15px;
    }
    input[type="file"] {
        width: 100%;
    }
}

.form-field-q {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 5;
    .fa {
        font-size: 175%;
    }
}

.form-red {
    .form-field {
        color: #444;
        background: #f3d0ca;
    }
    .text-contextual {
        color: @colorDanger;
    }
}

.form-blue {
    .form-field {
        color: #444;
        background: #c0e4f4;
    }
    .text-contextual {
        color: @colorPrimary;
    }
}

.file-hover {
    input {
        cursor: pointer;
    }
    &:hover {
        background: #ddd;
    }
}

.has-feedback label ~ .form-control-feedback {
    top: 27px;
    right: 4px;
    font-size: 20px;
}
.has-feedback .form-control-feedback {
    top: 4px !important;
    right: 4px;
    font-size: 20px;
}
