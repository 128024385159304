@ec-green: #3CB878;
@ec-red: #ED1C24;
@ec-pink: #F06EAA;
@ec-blue: #7ACCC8;

.eventCalendar {
  &__header {
    padding: 0 15px;
    > div {
      color: #888;
      line-height: 32px;
      text-align: center;
    }
  }

  &__title {
    color: @colorPrimary;
    display: inline-block;
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    a {
      padding: 13px;
    }
    span {
      padding: 0 20px;
    }
  }

  &__container {
    display: block;
  }

  &__agenda {
    margin-bottom: 20px;
    padding-left: 0;
    &__item {
      position: relative;
      display: block;
      padding: 0;
      margin: 20px 0 0;
      background: #fff;
      border: 1px solid #dee2e5;
      border-left-width: 3px;
      &--green {
        border-left-color: @ec-green;
      }
      &--red {
        border-left-color: @ec-red;
      }
      &--pink {
        border-left-color: @ec-pink;
      }
      &--blue {
        border-left-color: @ec-blue;
      }
      .left {
        position: absolute;
        top: 0;
        left: 0;
        height: 45px;
        width: 45px;
        text-align: center;
        line-height: 12px;
        font-size: 10px;
        > span:first-child {
          font-size: 20px;
          line-height: 26px;
        }
      }
      .body {
        display: block;
        margin-left: 45px;
        padding: 12px 20px;
        border-left: 1px solid #dee2e5;
        background: #eff7fa;
        color: @colorPrimary;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  &__filter {
    .control-label {
      display: none;
    }
    .controlContainer {
      width: 100%;
      padding: 0 !important;
      margin: 15px 0 0;
    }
    .checkbox {
      margin: 0 0 25px;
      .checkmark {
        display: block;
        cursor: pointer;
        position: absolute;
        height: 20px;
        width: 20px;
        text-align: center;
        color: #fff;
        background: #fff;
        border: 1px solid #dee2e5;
      }
      input {
        display: none;
      }
      label {
        padding-left: 30px;
      }
      &.checked {
        .checkmark {
          background: #dee2e5;
        }
      }
      &.checked.checkbox--green {
        .checkmark {
          border-color: @ec-green;
          background: @ec-green;
        }
      }
      &.checked.checkbox--red {
        .checkmark {
          border-color: @ec-red;
          background: @ec-red;
        }
      }
      &.checked.checkbox--pink {
        .checkmark {
          border-color: @ec-pink;
          background: @ec-pink;
        }
      }
      &.checked.checkbox--blue {
        .checkmark {
          border-color: @ec-blue;
          background: @ec-blue;
        }
      }
    }
  }

  .btn-link-grey {
    color: #DEE2E5;
    font-size: 20px;
    &:hover, &:focus {
      color: #94989b;
    }
  }

  .fc-event {
    font-weight: 400;
  }
  td.fc-today {
    background: none;
  }
  .fc-day-number {
    float: left !important;
    margin: 5px;
    padding: 6px 8px;
  }
  .fc-today .fc-day-number {
    color: #fff;
    background: @colorPrimary;
    border-radius: 100%;
  }
  td.fc-other-month {
    background: #faf9f9 !important;
  }

  .fc-event--green {
    background-color: @ec-green;
    border-color: @ec-green;
  }
  .fc-event--red {
    background-color: @ec-red;
    border-color: @ec-red;
  }
  .fc-event--pink {
    background-color: @ec-pink;
    border-color: @ec-pink;
  }
  .fc-event--blue {
    background-color: @ec-blue;
    border-color: @ec-blue;
  }

  .chart-subheader {
    margin: 0;
    padding: 8px 15px;
  }
  .row {
    margin: 0 -15px;
  }
  [class*="col-"] {
    padding: 0 15px;
  }
}
