.ui-date-picker {
    &.modal {
        z-index: 1150;
        .modal-dialog {
            height: 500px;
            min-width: 320px;
            max-width: 320px;
            margin: auto;
            background: #fff;
            box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
        }
        .modal-content {
            border: none;
            box-shadow: none;
        }
        .backdrop {
            display: block;
            z-index: auto;
        }
    }
    #heading {
        padding: 15px;
        background: @brand-primary;
        .year {
            cursor: pointer;
            color: #fff;
            opacity: 0.66;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
        .date {
            color: #fff;
            font-size: 34px;
            font-weight: 700;
            line-height: 40px;
            margin-top: 5px;
            span {
                display: block;
            }
        }
    }

    #pages {
        height: 350px;
        padding: 8px;
        background: #fff;
    }

    #years {
        overflow: auto;
        height: 350px;
        padding: 8px;
        background: #fff;
        .each-year {
            font-size: 14px;
            font-weight: 400;
            line-height: 41px;
            text-align: center;
            cursor: pointer;
            &.selected {
                color: @brand-primary;
                font-size: 24px;
                font-weight: 700;
            }
            &:hover {
                background: #e6e6e6;
            }
        }
    }

    #buttons {
        text-align: right;
        padding: 8px;
        padding-left: 15px;
    }
}

.ui-date-picker-calendar {
    &.navigator {
        max-height: 46px;
        position: relative;
        .nav-month-year {
            font-size: 14px;
            font-weight: 500;
            line-height: 32px;
            text-align: center;
        }
        .btn {
            position: absolute;
            background: none;
        }
    }
    &.days-of-week {
        display: flex;
        text-align: center;
        line-height: 41px;
        font-weight: 400;
        .each-days-of-week {
            color: #9e9e9e;
            width: 41px;
            margin: 1px;
        }
    }
    &.days-of-month {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        line-height: 41px;
        font-weight: 400;
        .each-days-of-month {
            width: 41px;
            margin: 1px;
            cursor: pointer;
            border-radius: 100%;
            &:hover {
                background: fade(@brand-primary, 50%);
            }
            &.is-today {
                cursor: default;
                color: #fff;
                background: @brand-primary;
            }
            &.is-disabled-day {
                color: #9e9e9e;
            }
            &.is-non-selectable {
                cursor: default;
                background: none;
            }
        }
    }
}

.date-picker-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
}

@media (min-height: 500px) {
    .ui-date-picker {
        &.modal {
            .modal-dialog {
                margin-top: calc(~"50vh - 250px");
            }
        }
    }
}
