.btn {
    padding: 0 20px;
    line-height: 30px;
    //border: none;
    .border-radius(0);
    /*.transition(all .2s ease-out);*/
    /*.box-shadow(0 2px 5px rgba(0,0,0,0.33));*/
    opacity: 1.0;
}
.btn:hover, .btn:focus, .btn.focus, .btn:active, .btn.active, .open > .dropdown-toggle.btn {
    opacity: 0.8;
}
.btn:hover, .btn:focus, .btn.focus {
    opacity: 0.5;
    /*.box-shadow(0px 2px 4px 1px rgba(0,0,0,0.33));*/
}

/* Sizes */
.btn-lg {
    padding: 10px 20px;
}

/* Circle button */
.btn-circle {
    padding: 0 7px;
    border: 0;
    .border-radius(100%);
    color: #fff;
    background: @colorDefault;
}
.btn-circle:hover, .btn-circle:focus, .btn-circle.focus, .btn-circle:active, .btn-circle.active, .open > .dropdown-toggle.btn-circle {
    opacity: 1;
}

/* Link button */
.btn-link {
    border: none;
    background: transparent;
    .box-shadow(none);
}
.btn-link:hover, .btn-link:focus, .btn-link.focus, .btn-link:active, .btn-link.active, .open>.dropdown-toggle.btn-link {
    opacity: 1;
    border: none;
    background: transparent;
    .box-shadow(none);
}

/* Text button (fake) */
.btn-static {
    font-size: inherit;
    cursor: initial;
    border: none;
    background: transparent;
    .box-shadow(none);
}
.btn-static:hover, .btn-static:focus, .btn-static.focus, .btn-static:active, .btn-static.active, .open>.dropdown-toggle.btn-static {
    opacity: 1;
    border: none;
    background: transparent;
    .box-shadow(none);
}

/* Caret in buttons */
.icon-caret {
    line-height: 30px;
    margin-left: 5px;
}

/* Alternative */
.btn-alt {
    line-height: 28px;
    background: #fff;
    border: 1px solid transparent;
    &:focus, &:hover, &:active, &.active {
        opacity: 1;
    }
    &.btn-default {
        color: @colorDefault;
        border-color: @colorDefault;
        &:focus, &:hover, &:active, &.active {
            color: #fff;
            background: @colorDefault;
        }
    }
    &.btn-primary {
        color: @colorPrimary;
        border-color: @colorPrimary;
        &:focus, &:hover, &:active, &.active {
            color: #fff;
            background: @colorPrimary;
        }
    }
    &.btn-info {
        color: @colorInfo;
        border-color: @colorInfo;
        &:focus, &:hover, &:active, &.active {
            color: #fff;
            background: @colorInfo;
        }
    }
    &.btn-success {
        color: @colorSuccess;
        border-color: @colorSuccess;
        &:focus, &:hover, &:active, &.active {
            color: #fff;
            background: @colorSuccess;
        }
    }
    &.btn-warning {
        color: @colorWarning;
        border-color: @colorWarning;
        &:focus, &:hover, &:active, &.active {
            color: #fff;
            background: @colorWarning;
        }
    }
    &.btn-danger {
        color: @colorDanger;
        border-color: @colorDanger;
        &:focus, &:hover, &:active, &.active {
            color: #fff;
            background: @colorDanger;
        }
    }
}

.btn-hero {
    display: block;
    position: relative;
    margin-bottom: 25px;
    font-size: 21px;
    line-height: 50px;
    padding: 14px;

    color: @colorPrimary;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 5px;

    &:focus, &:hover {
        color: @colorPrimary;
        background: #fff;
        border: 1px solid #dedede;
    }

    > i {
        position: relative;
        top: 4px;
        left: -20px;
        width: 50px;
        height: 50px;
        font-size: 30px;
        color: #fff;
        background: #2098ca;
        border-radius: 50px;
        line-height: 50px;
    }
}
