/* UI mixins */
.stripes(@size) {
    background-image: -webkit-linear-gradient(-45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(-45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(-45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    -webkit-background-size: @size @size;
    background-size: @size @size;
}

/* Vendor mixins */
.box-sizing(@box-model) {
    -webkit-box-sizing: @box-model; // Safari <= 5
    -moz-box-sizing: @box-model; // Firefox <= 19
    box-sizing: @box-model;
}

.border-top-radius(@radius) {
    border-top-right-radius: @radius;
    border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
    border-bottom-right-radius: @radius;
    border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
    border-bottom-left-radius: @radius;
    border-top-left-radius: @radius;
}
.border-radius(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.25)) {
    -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
    box-shadow: @shadow;
}
.box-shadow-from-to(@shadow-from, @shadow-to) {
    -webkit-box-shadow: @shadow-from, @shadow-to; // iOS <4.3 & Android <4.1
    box-shadow: @shadow-from, @shadow-to;
}

.transition(@transition) {
    -webkit-transition: @transition;
    -moz-transition: @transition;
    transition: @transition;
}

.rotate(@degrees) {
    -webkit-transform: rotate(@degrees);
    -ms-transform: rotate(@degrees); // IE9 only
    transform: rotate(@degrees);
}
.scale(@ratio; @ratio-y...) {
    -webkit-transform: scale(@ratio, @ratio-y);
    -ms-transform: scale(@ratio, @ratio-y); // IE9 only
    transform: scale(@ratio, @ratio-y);
}
.translate(@x; @y) {
    -webkit-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y); // IE9 only
    transform: translate(@x, @y);
}
.skew(@x; @y) {
    -webkit-transform: skew(@x, @y);
    -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    transform: skew(@x, @y);
}
.translate3d(@x; @y; @z) {
    -webkit-transform: translate3d(@x, @y, @z);
    transform: translate3d(@x, @y, @z);
}

.rotateX(@degrees) {
    -webkit-transform: rotateX(@degrees);
    -ms-transform: rotateX(@degrees); // IE9 only
    transform: rotateX(@degrees);
}
.rotateY(@degrees) {
    -webkit-transform: rotateY(@degrees);
    -ms-transform: rotateY(@degrees); // IE9 only
    transform: rotateY(@degrees);
}
.perspective(@perspective) {
    -webkit-perspective: @perspective;
    -moz-perspective: @perspective;
    perspective: @perspective;
}
.perspective-origin(@perspective) {
    -webkit-perspective-origin: @perspective;
    -moz-perspective-origin: @perspective;
    perspective-origin: @perspective;
}
.transform-origin(@origin) {
    -webkit-transform-origin: @origin;
    -moz-transform-origin: @origin;
    -ms-transform-origin: @origin; // IE9 only
    transform-origin: @origin;
}

.opacity(@opacity) {
    opacity: @opacity;
    // IE8 filter
    @opacity-ie: (@opacity * 100);
    filter: ~"alpha(opacity=@{opacity-ie})";
}

/* Utility mixins */
.clearfix() {
    &:before,
        &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.size(@width; @height) {
    width: @width;
    height: @height;
}
.square(@size) {
    .size(@size; @size);
}

.resizable(@direction: both) {
    // Options: horizontal, vertical, both
    resize: @direction;
    // Safari fix
    overflow: auto;
}

.text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.img-retina(@file-1x; @file-2x; @width-1x; @height-1x) {
    background-image: url("@{file-1x}");

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (   min--moz-device-pixel-ratio: 2),
        only screen and (     -o-min-device-pixel-ratio: 2/1),
        only screen and (        min-device-pixel-ratio: 2),
        only screen and (                min-resolution: 192dpi),
        only screen and (                min-resolution: 2dppx) {
        background-image: url("@{file-2x}");
        background-size: @width-1x @height-1x;
    }
}
