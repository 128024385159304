.panel {
  border-radius: 0;
  > .panel-heading {
    padding: 10px 20px;
  }
  > .panel-body {
    padding: 10px 20px;
  }
}

.panel-default {
  > .panel-heading {
    background: #eff7fa;
    border-color: transparent;
    border-radius: 0;
  }
  .panel-title {
    color: @colorPrimary;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
